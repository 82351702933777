// Sizes:
$desktopWidth: 1320px;
$tabletWidth: 768px;
$mobileWidth: 375px;

$demoPageFullScreenMinHeight: 512px;

// Colors:
$fgColor: #2a84fc;
$bgColor: #9763f6;
$buttonOnHoverColor: #78dbc3;
$gotoOnHoverColor: #ffffff;
$inactiveColor: #828282;
$resetActiveColor: #c44c32;
$trainReadyColor: #c16902;
$trainMainColor: #e97e01;
$runReadyColor: #0da85d;
$runMainColor: #0ecd71;
$iconNeutralColor: #b4b4b4;
$onHoverColor: #292828;
$insertionColor: #343030;
$errorColor: #f06b4d;
$urlOnHoverColor: #78dbc3;
$spinnerColor: #78dbc3;
$selectColor: #78dbc3;

//:export {
//  tabletWidth: $tabletWidth;
//  //mobileWidth: $mobileWidth;
//}
