@import '../../../assets/styles/constants';

@keyframes tile-substrate-on-hover-in {
  from {
    opacity: 0;
    transform: scaleX(1);
  }
  to {
    opacity: 1;
    transform: scaleX(1.1);
  }
}
@keyframes tile-substrate-on-hover-out {
  from {
    opacity: 1;
    transform: scaleX(1.1);
  }
  to {
    opacity: 0;
    transform: scaleX(1);
  }
}

@keyframes translate-image-on-hover-in {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}
@keyframes translate-image-on-hover-out {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes resize-image-on-hover-in {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
@keyframes resize-image-on-hover-out {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

@keyframes translate-arrow-in {
  from {
    margin-left: 4px;
  }
  to {
    margin-left: 36px;
  }
}
@keyframes translate-arrow-out {
  from {
    margin-left: 36px;
  }
  to {
    margin-left: 4px;
  }
}

.section.select-demo {
  margin: 0 0 150px;

  .select-demo-wrapper {
    margin-top: 90px;
    position: relative;
  }

  .insertion {
    position: absolute;
    top: 0;
    left: 0;

    transform: translateY(50%);

    width: 100%;
    height: 402px;
    background-color: #343030;

    @media (min-width: 839px) and (max-width: 1259px) {
      height: 481px;
      width: calc(100% - 32px);
      margin: 0 16px;
    }

    @media (max-width: 839px) {
      display: none;
    }
  }

  .select-demo-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.tile {
  $tileDescHeight: 160px;
  $tileHeight: calc(360px + #{$tileDescHeight});

  width: 360px;
  height: $tileHeight;

  margin: 0 30px;
  border-radius: 2px;

  @media (max-width: $desktopWidth) {
    margin: 30px 30px;
  }

  @media (max-width: $tabletWidth) {
    margin: 30px 16px;
  }

  cursor: pointer;

  position: relative;

  .substrate {
    opacity: 0;

    position: absolute;
    top: 0;
    left: 0;

    background: #514e4e;
    height: $tileHeight;
    width: 360px;

    @media (max-width: $tabletWidth) {
      width: 100%;
    }

    animation-name: tile-substrate-on-hover-out;
    animation-duration: 200ms;
    animation-timing-function: ease-out;
  }

  .image-wrapper {
    animation-name: translate-image-on-hover-out;
    animation-duration: 200ms;
    animation-timing-function: ease-out;
  }

  .image-wrapper img {
    animation-name: resize-image-on-hover-out;
    animation-duration: 200ms;
    animation-timing-function: ease-out;
  }

  .demo-desc {
    position: absolute;
    left: 0;
    bottom: 0;

    height: #{$tileDescHeight};

    display: flex;
    flex-direction: column;
  }

  img {
    width: 100%;
    display: block;
    border-radius: 2px;
  }

  p {
    all: unset;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  .demo-name {
    font-size: 24px;
    line-height: 28px;
    margin: 24px 0 40px 0;
    color: #ffffff;
  }

  .try-label-wrapper {
    display: flex;
    align-items: center;
  }

  .try-label {
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #b4b4b4;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
  }

  .plus-icon,
  .arrow-icon {
    display: flex;
    align-items: center;
    svg > * {
      fill: #b4b4b4;
      stroke: #b4b4b4;
    }
  }

  .plus-icon {
    margin-left: 16px;
  }

  .arrow-icon {
    margin-left: 4px;

    animation-name: translate-arrow-out;
    animation-duration: 200ms;
    animation-timing-function: ease-out;
  }
}

.tile:hover {
  $animation-duration: 200ms;

  .substrate {
    opacity: 1;
    transform: scaleX(1.1);

    animation-name: tile-substrate-on-hover-in;
    animation-duration: $animation-duration;
    animation-timing-function: ease-in;
  }
  .image-wrapper {
    transform: translateY(-10px);

    animation-name: translate-image-on-hover-in;
    animation-duration: $animation-duration;
    animation-timing-function: ease-in;
  }
  .image-wrapper img {
    transform: scale(1.1);

    animation-name: resize-image-on-hover-in;
    animation-duration: $animation-duration;
    animation-timing-function: ease-in;
  }
  .arrow-icon {
    margin-left: 36px;

    animation-name: translate-arrow-in;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
  }
}
