@import 'typography';

@mixin hot-key-label-styles {
  background: #ffffff;
  white-space: nowrap;
  padding: 5px 8px;
  border-radius: 4px;
  box-sizing: border-box;

  @include shortcut-key-font;
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: center;
}
