@import '../../../assets/styles/constants';

// TODO: Move to global SCSS constants?
// TODO: Review names and usage. We should reuse it in all places.
$headerHeight: 70px;
$toolsHeight: 194px;
$topMargin: 18px;

.image-player {
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

@media (min-height: $demoPageFullScreenMinHeight) {
  .image-player {
    .image-wrapper,
    .canvas-wrapper {
      position: fixed;
      top: $headerHeight + $topMargin;
      left: 0;
      width: 100%;
      height: calc(100% - #{$headerHeight + $toolsHeight + $topMargin});
    }
  }
}

@media (max-height: $demoPageFullScreenMinHeight) {
  .image-player {
    position: relative;

    .image-wrapper,
    .canvas-wrapper {
      padding: 60px;
    }

    .canvas-wrapper {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
