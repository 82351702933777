.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 49, 85, 0.35);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  width: 350px;
  min-height: 100px;
  background-color: red;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid black;
  position: relative;
}

.close-icon {
  position: absolute;
  left: calc(100% - 10px);
  top: -10px;
  background-color: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  font-size: 10px;
}

.popup-wrapper.processing {
  background-color: rgba(77, 76, 76, 0.5);

  .popup {
    all: unset;
  }

  .message {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -0.02em;

    color: #ffffff;
  }
}
