@import '../../assets/styles/constants';
@import '../../assets/styles/typography';

.logo-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;

  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
}

.logo-wrapper:hover {
  color: $urlOnHoverColor;
}

.logo-wrapper .demo-label {
  background-color: $bgColor;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2px;
  border-radius: 2px;
  margin-left: 6px;

  @include demo-label-font;
}
