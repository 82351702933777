.not-found {
  display: flex;
  justify-content: center;
  margin-top: 100px;

  h1 {
    all: unset;
    color: white;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
  }
}
