@import '../../../assets/styles/constants';

footer {
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .copyright {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
    opacity: 0.2;

    margin-top: 33px;
  }
}
