@import '../../assets/styles/constants';
@import '../../assets/styles/typography';
@import '../../assets/styles/animations';

nav {
  height: 70px;
  width: 100%;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav .left {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 40px;

  @media (max-width: $tabletWidth) {
    margin-left: 20px;
  }
}

nav.fade-in-on-open {
  animation-name: header-fade-in-with-delay;
  animation-duration: 1000ms;
  animation-timing-function: ease-in;
}

nav.dark {
  background: #0d0d0d;
}

.contact-button {
  width: 242px;
  height: 100%;
  background-color: $bgColor;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  color: white;
  @include contact-button-font;

  @media (max-width: $tabletWidth) {
    width: 144px;
  }

  .icon-wrapper {
    margin-left: 10px;
    svg > * {
      fill: white;
    }
  }

  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
}

.contact-button:hover {
  background-color: $buttonOnHoverColor;
}

.dropdown {
  position: relative;
  margin-left: 35px;

  @include dropbox-font;
  color: white;

  .selected-option {
    height: 35px;
    padding: 8px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    cursor: pointer;

    background-color: $onHoverColor;
    svg {
      display: block;
      * {
        fill: white;
      }
    }
    .icon-wrapper {
      margin-left: 6px;
    }
  }

  .content {
    transform: scaleY(0);
    transition: transform 100ms ease-in;
  }

  .content.opened {
    transform: scaleY(1);

    .option {
      opacity: 1;
    }
  }

  .tip {
    height: 8px;
    width: 16px;

    position: absolute;
    left: 40px;
    bottom: 0;
    transform: translateY(calc(100% + 17px));

    clip-path: polygon(0 100%, 50% 0, 100% 100%);

    background: #000000;
    opacity: 0.9;
  }

  .options-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(calc(100% + 25px));
    z-index: 999999;

    background: #000000;
    opacity: 0.9;
    border-radius: 2px;

    display: flex;
    flex-direction: column;

    padding: 8px 0;
  }

  .option {
    height: 40px;
    white-space: nowrap;

    display: flex;
    align-items: center;

    padding: 0 20px 0 15px;
    cursor: pointer;

    border-left: transparent 3px solid;

    opacity: 0;
    transition: opacity 200ms ease-in 50ms;
  }

  .option:hover {
    background-color: $onHoverColor;
    opacity: 1;
  }

  .option.active {
    border-left: white 3px solid;
  }
}
