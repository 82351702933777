@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/constants';
@import '../../../../assets/styles/mixins';

.button {
  cursor: pointer;
  position: relative;

  .shortcut-key {
    @include hot-key-label-styles;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: block;
    }
  }

  .label {
    margin: 4px 0;
    @include button-label-font;
  }

  transition: all 150ms ease-in;
  transition-property: background-color, color;

  svg * {
    transition: fill 150ms ease-in;
  }
}

.button.with-shortcut .shortcut-key {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);

  min-width: 24px;
  min-height: 24px;

  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
}

.button.with-tooltip .tooltip-wrapper {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);

  .tooltip {
    background-color: #000000;
    opacity: 0.9;
    border-radius: 2px;
    min-width: 74px;
    min-height: 40px;
    text-align: center;

    color: white;

    @include tooltip-font;
  }

  .message {
    margin: 17px 17px 11px;
  }

  .tip {
    background-color: #000000;
    opacity: 0.9;

    width: 16px;
    height: 8px;

    clip-path: polygon(0 0, 100% 0, 50% 100%);
  }

  .shortcut-key {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -60%);

    padding: 1px 3px;
    min-width: 16px;
    min-height: 16px;
  }
}

.button:hover {
  background-color: $onHoverColor;

  .shortcut-key {
    background: #ffffff;
    opacity: 1;
  }

  .tooltip-wrapper {
    display: flex;
  }
}

.button.active {
  background: #ffffff;
  color: #000000;
  cursor: default;
}

.button.inactive {
  cursor: not-allowed;
  .label {
    color: #434343;
  }
}
