@import '../../../assets/styles/constants';
@import '../../../assets/styles/typography';

.value {
  background-color: $bgColor;
}

.value .benefits {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .benefit {
    width: 200px;
    height: 280px;
    margin: 66px;
    flex-shrink: 0;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .icon-wrapper {
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 40px;
  }

  .description {
    color: white;
    @include benefit-desc-font;
  }
}
