@import '../../../assets/styles/constants';
@import '../../../assets/styles/typography';

// TODO: Review and move to SCSS constants.
$lrMargin: 27px;
$tbMargin: 20px;

.tools-panel {
  display: flex;
  flex-direction: column;

  width: calc(100% - #{2 * $lrMargin});
  margin: $tbMargin $lrMargin;

  @media (min-height: $demoPageFullScreenMinHeight) {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}

.row {
  display: flex;
  flex-direction: column;
}

.row-content {
  display: flex;
  align-items: center;
  margin: auto;
}

// Make the row scrollable:

.row-wrapper > .row-tools {
  overflow-x: scroll;
}

.row.row-tools > .row-content {
  margin: 50px auto 0;
  // NOTE: top margin is required to make sure that the tooltip is visible.
  // TODO: Add margins for item that for the most left item there is no cut of tooltip.
}

.row.row-checkboxes {
  margin-bottom: -50px;
}

// TODO: Tooltips must be always on top...

// Others:

.item-panel.layer-checkboxes {
  border-radius: 35px;

  padding: 11px 24px;
  box-sizing: border-box;
}

.item {
  width: 86px;
  height: 86px;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  color: #ffffff;
}

.item.message {
  width: 120px;
  @include item-message-font;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.fg {
  svg {
    .fg {
      fill: $fgColor;
    }
  }
  .shortcut-key {
    color: $fgColor;
  }
}
.button.fg.active {
  color: $fgColor;
}

.button.bg {
  svg {
    .bg {
      fill: $bgColor;
    }
  }
  .shortcut-key {
    color: $bgColor;
  }
}
.button.bg.active {
  color: $bgColor;
}

.button.switch svg {
  .fg {
    fill: $fgColor;
  }
  .bg {
    fill: $bgColor;
  }
}

.button.reset {
  svg > * {
    fill: white;
  }
  .shortcut-key {
    color: $resetActiveColor;
  }
}
.button.reset:not(.active):hover {
  background-color: $resetActiveColor;
}
.button.reset.active {
  color: $resetActiveColor;
  svg > * {
    fill: $resetActiveColor;
  }
}

.button.train {
  svg > path {
    fill: white;
  }
  .shortcut-key {
    color: $trainMainColor;
  }
}
.button.train:not(.active) {
  background-color: $trainReadyColor;
}
.button.train:not(.active):hover {
  background-color: $trainMainColor;
}
.button.train.active {
  color: $trainMainColor;
  svg {
    path {
      fill: $trainMainColor;
    }
  }
}

.button.run {
  svg > path {
    fill: white;
  }
  .shortcut-key {
    color: $runMainColor;
  }
}
.button.run:not(.active) {
  background-color: $runReadyColor;
}
.button.run:not(.active):hover {
  background-color: $runMainColor;
}
.button.run.active {
  color: $runMainColor;
  svg {
    path {
      fill: $runMainColor;
    }
  }
}

.item-panel.prev-next {
  border-radius: 39px;

  .button {
    width: 32px;
    height: 32px;
    border-radius: 33px;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon-wrapper svg > * {
      fill: white;
    }
  }

  .button.active {
    .icon-wrapper svg > * {
      fill: black;
    }
  }
}

.slice-navigator {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #686868;

  width: 50px;
  box-sizing: border-box;
  padding: 0 8px;
}

.item-panel {
  background-color: #0e0e0e;
  opacity: 0.9;
  margin: 8px 20px 8px 0;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
}
