@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&family=Work+Sans:wght@400;500&family=IBM+Plex+Sans&display=swap');
@import 'typography.scss';
@import 'constants';

html {
  min-height: 100%;
  background-color: #212121;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  all: unset;
  @include h1-font;
  color: #eae8e8;
}

h2 {
  all: unset;
  @include h2-font;
  color: #eae8e8;
}

input,
textarea {
  all: unset;
}

*::selection {
  background-color: $selectColor;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.section {
  display: flex;
  justify-content: center;

  &-title {
    max-width: 820px;
    margin: 0 121px;

    @media (max-width: $tabletWidth) {
      margin: 40px;
    }

    @media (max-width: $mobileWidth) {
      margin: 16px;
    }
  }

  &-content {
    max-width: 1440px;
    width: 100%;
  }
}
