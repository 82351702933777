@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/mixins';

form.checkboxes {
  display: flex;
  @include checkbox-label-font;
  color: white;

  .checkbox {
    margin-right: 18px;

    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;

    position: relative;

    input {
      position: absolute;
      height: 0;
      width: 0;
      opacity: 0;
      cursor: pointer;
    }

    .shortcut-key {
      @include hot-key-label-styles;
    }

    .checkmark {
      background: #3e3e3e;
      border-radius: 2px;
      width: 16px;
      height: 16px;

      margin-right: 6px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .shortcut-key {
      position: absolute;
      left: 50%;
      top: -20px;
      transform: translateX(-50%);
      padding: 1px 3px;
      min-width: 14px;
      min-height: 14px;
      display: none;
    }
  }
  .checkbox:last-child {
    margin-right: unset;
  }

  .checkbox:hover {
    .shortcut-key {
      display: unset;
    }
  }
}
