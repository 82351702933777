@import '../../../assets/styles/typography';
@import '../../../assets/styles/animations';
@import '../../../assets/styles/constants';

header {
  width: 100%;
  height: 688px;

  position: relative;

  //.image-wrapper {
  //  opacity: 0.1;
  //
  //  img {
  //    height: 688px;
  //    width: 100%;
  //    object-fit: cover;
  //  }
  //}

  .video-wrapper {
    opacity: 0.1;

    video {
      height: 688px;
      width: 100%;
      object-fit: cover;
    }
  }

  nav {
    position: absolute;
    left: 0;
    top: 0;
  }

  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      90deg,
      #212121 12.86%,
      rgba(30, 31, 30, 0) 93.42%
    );
  }
}

.section.intro {
  margin-top: 169px;

  .text {
    margin: 0 60px;

    @media (max-width: $tabletWidth) {
      margin: 0 16px;
    }
  }

  .main-text {
    max-width: 817px;

    animation-name: header-main-text-popup;
    animation-duration: 300ms;
    animation-timing-function: ease-in;
  }

  .try-demo-wrapper {
    margin-top: 60px;

    display: flex;
    animation-name: header-fade-in-with-delay;
    animation-duration: 1000ms;
    animation-timing-function: ease-in;

    a {
      all: unset;
    }
  }

  .try-demo {
    color: white;
    @include call-for-action-font;
    cursor: pointer;
  }

  @supports (-webkit-text-stroke: 1px black) {
    .try-demo {
      color: transparent;
      -webkit-text-stroke: 1px $gotoOnHoverColor;
      //-webkit-text-fill-color: white;

      transition: color 200ms ease-in;
    }
    .try-demo:hover {
      color: $gotoOnHoverColor;
    }
  }
}

header {
  a.play-button-link {
    all: unset;
  }

  .play-button {
    color: $bgColor;
    background-color: white;
    width: 270px;
    height: 144px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transition: all 200ms ease-in;
    transition-property: color, background-color;
  }

  .play-button:hover {
    background-color: $buttonOnHoverColor;
    color: white;
  }

  .play-button .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .play-button .title {
    margin-top: 11px;
    @include header-play-button-font;
  }
}

header {
  .header-content {
    display: flex;
    align-items: center;
  }

  @media (min-width: $tabletWidth) and (max-width: $desktopWidth) {
    .header-content {
      flex-direction: column;
      align-items: flex-start;
    }

    .play-button-wrapper {
      margin: 45px 60px;
    }
  }

  @media (max-width: $tabletWidth) {
    .header-content {
      flex-direction: column;
      align-items: flex-start;
    }

    .play-button-wrapper {
      margin: 88px 16px;
    }
  }
}

header {
  .play-button-wrapper {
    animation-name: header-fade-in-with-delay;
    animation-duration: 1000ms;
    animation-timing-function: ease-in;
  }
}
