@import 'constants';

@mixin h1-font {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 90px;
  line-height: 90px;
  letter-spacing: -0.02em;

  @media (min-width: $tabletWidth) and (max-width: $desktopWidth) {
    font-size: 52px;
    line-height: 52px;
  }

  @media (max-width: $tabletWidth) {
    font-size: 40px;
    line-height: 40px;
  }
}

@mixin h2-font {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 70px;
  letter-spacing: -0.02em;

  @media (min-width: $tabletWidth) and (max-width: $desktopWidth) {
    font-size: 40px;
    line-height: 40px;
  }

  @media (max-width: $tabletWidth) {
    font-size: 32px;
    line-height: 40px;
  }
}

@mixin form-label-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin form-value-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.03em;
}

@mixin form-error-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}

@mixin shortcut-key-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

@mixin button-label-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

@mixin checkbox-label-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

@mixin tooltip-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

@mixin dropbox-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

@mixin item-message-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

@mixin demo-label-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

@mixin contact-button-font {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 90px;
  letter-spacing: -0.02em;
  text-transform: capitalize;

  @media (max-width: $mobileWidth) {
    font-size: 18px;
    line-height: 90px;
  }
}

@mixin call-for-action-font {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 90px;
  line-height: 90px;
  letter-spacing: -0.02em;

  @media (min-width: $tabletWidth) and (max-width: $desktopWidth) {
    font-size: 52px;
    line-height: 52px;
  }

  @media (max-width: $tabletWidth) {
    font-size: 40px;
    line-height: 40px;
  }
}

@mixin benefit-desc-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}

@mixin header-play-button-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
