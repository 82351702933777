@import '../../../assets/styles/constants';

.message-panel {
  position: fixed;
  right: 30px;
  top: 143px;
  width: 500px;
  z-index: 999999;

  display: flex;
  flex-direction: column;
}

.message-panel .message {
  border-radius: 8px;
  padding: 4px;
  margin-bottom: 30px;

  background: #000000;
  opacity: 0.9;
  color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: flex-start;

  .content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .title {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -0.02em;

    margin: 30px 0 14px 0;
  }

  .description {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;

    margin-bottom: 32px;
  }

  .message-icon-wrapper {
    margin: 20px;
    svg > * {
      fill: #c95c44;
    }
  }

  .close-icon-wrapper {
    margin: 12px;
    border-radius: 4px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;

    svg {
      display: block;
      * {
        fill: #ffffff;
      }
    }
  }

  .close-icon-wrapper:hover {
    background-color: $onHoverColor;
  }
}
