.canvas-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.canvas-wrapper.active {
  z-index: 1;
}
