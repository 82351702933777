@import '../../../assets/styles/typography';
@import '../../../assets/styles/constants';

.contact-area {
  position: relative;

  .image-wrapper {
    opacity: 0.1;
    img {
      object-fit: cover;
      width: 100%;
      height: 1135px;
    }
  }

  .contact-form-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    $paddingTop: 107px;
    padding-top: $paddingTop;
    height: calc(100% - #{$paddingTop});

    background: linear-gradient(
      90deg,
      #212121 12.86%,
      rgba(30, 31, 30, 0) 93.42%
    );
  }
}

.contact-form {
  margin: 0 60px;

  form.contact {
    display: flex;
    flex-direction: column;

    width: 720px;
    margin-top: 150px;
  }
}

@media (max-width: $tabletWidth) {
  .contact-form {
    margin: 0 16px;

    form.contact {
      width: 100%;
    }
  }
}

form.contact {
  label {
    @include form-label-font;
    color: $inactiveColor;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    color: white;
    @include form-value-font;

    label.filled {
      top: 0 !important;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      transition: top 200ms ease-in;
    }

    input,
    textarea {
      margin-top: 32px;
      padding-bottom: 4px;
      width: 100%;
      border-bottom: 1px solid $inactiveColor;
      transition: border-bottom-color 200ms ease-in;
    }

    input::placeholder {
      opacity: 0;
      transition: opacity 100ms ease-in;
    }

    textarea::placeholder {
      opacity: 0;
      transition: opacity 100ms ease-in;
    }
  }

  .input-wrapper:hover,
  .input-wrapper:focus-within {
    input::placeholder {
      opacity: 0.25;
    }
    textarea::placeholder {
      opacity: 0.25;
      transition: opacity 100ms ease-out 200ms;
    }
  }

  .input-wrapper:hover,
  .input-wrapper:focus-within {
    label {
      top: 0 !important;
    }
  }
  .input-wrapper:focus-within {
    label {
      color: white;
    }
    input,
    textarea {
      border-bottom-color: white;
    }
  }

  .input-wrapper.email {
    label {
      top: 40px;
    }
    input {
      height: 40px;
    }
    input.invalid {
      color: $errorColor;
    }
  }

  .input-wrapper.message {
    margin-top: 20px;
    label {
      top: 80px;
    }
    textarea {
      height: 80px;
    }
  }

  input[type='submit'] {
    all: unset;
    margin-top: 97px;

    cursor: pointer;
    height: 106px;

    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;

    color: #ffffff;
    text-align: center;

    background-color: $bgColor;

    transition: all 200ms ease-in;
    transition-property: background-color, opacity;
  }

  input[type='submit']:hover {
    background-color: $buttonOnHoverColor;
  }

  input[type='submit']:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: $inactiveColor;
  }

  .error-message {
    width: 100%;
    color: $errorColor;
    padding-top: 9px;
    opacity: 0;
    height: 30px;
    @include form-error-font;
    transition: opacity 200ms ease-in;
  }
  .error-message.filled {
    opacity: 1;
  }
}

form.contact {
  .success-message {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.03em;
    color: white;
  }
}

form.contact {
  .content-wrapper {
    position: relative;
  }

  .spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success-message-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    opacity: 0;

    transition: all 200ms ease-in;
    transition-property: opacity, top;
  }
}

form.contact {
  .input-forms.hidden {
    visibility: hidden;
  }
  .success-message-wrapper.is-sent {
    top: 0;
    opacity: 1;
  }
}
