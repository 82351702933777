@import '../../../assets/styles/constants';

.section.team {
  .bio-wrapper {
    background-color: $insertionColor;
  }

  .insert {
    height: 500px;
    width: 320px;
    background-color: $bgColor;
  }

  .photo-wrapper {
    img {
      max-height: 500px;
    }
  }
}

@media (min-width: $desktopWidth) {
  .section.team {
    margin: 200px 0 100px;
    // TODO: Increase margin?

    .bio-wrapper {
      display: flex;
      flex-direction: column;
      padding: 150px 150px 150px 250px;
      margin-left: 300px;

      position: relative;

      .photo-wrapper {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
      }

      .insert {
        position: absolute;
        bottom: -10%;
        left: -270px;
      }
    }
  }
}

@media (min-width: $tabletWidth) and (max-width: $desktopWidth) {
  .section.team {
    margin: 450px 20px 20px;

    .bio-wrapper {
      padding: 320px 24px 168px;
      position: relative;

      .photo-wrapper {
        position: absolute;
        top: 0;
        left: 40px;
        transform: translate(0, -50%);
      }

      .insert {
        position: absolute;
        top: 0;
        left: 60px;
        transform: translate(0, -55%);
      }
    }
  }
}

@media (max-width: $tabletWidth) {
  .section.team {
    margin: 450px 0 20px;

    .bio-wrapper {
      padding: 320px 33px 168px;
      position: relative;

      .photo-wrapper {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .insert {
        position: absolute;
        top: 0;
        left: 52%;
        transform: translate(-50%, -52%);
      }
    }
  }
}

.section.team {
  .name {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 52px;
    line-height: 60px;
    letter-spacing: -0.03em;

    color: white;
  }

  .role-wrapper {
    display: flex;
    align-items: center;
    //justify-content: space-between;

    margin-top: 13px;
  }

  .role {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.03em;

    color: $bgColor;
  }

  .bio {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.03em;
    color: white;

    margin-top: 50px;
  }
}

.section.team {
  .contacts {
    margin-top: 40px;

    display: flex;
  }

  a.contact {
    all: unset;
    color: white;
    cursor: pointer;

    transition-property: color;
    transition-duration: 200ms;
    transition-timing-function: ease-in;
  }

  a.contact:hover {
    color: $urlOnHoverColor;
  }

  a.contact > div {
    display: flex;
    align-items: center;
  }

  .website-name {
    margin: 0 10px;
  }
}
